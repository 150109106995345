import React, { Component } from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import $ from "jquery";
import T from "../Helpers/Translations";
import * as actions from "../Store/Actions/type";
import * as userActionCreater from "../Store/Actions/UserActions";

class CancellationPolicy extends Component {
  state = {
    user: {},
    prices: {}
  };

  getPrices = () => {
    let { getPrices, errorHandler } = this.props;
    getPrices()
      .then(res => {
        this.setState({
          prices: res.data
        });
      })
      .catch(errorHandler)
      .finally(() => {
        this.setState({
          isLoading: false
        });
      });
  };

  getTestimonials = () => {
    let { getTestimonials, errorHandler } = this.props;
    getTestimonials()
      .then(res => {
        this.setState({
          testimonials: res.data
        });
      })
      .catch(errorHandler)
      .finally(() => {
        this.setState({
          isLoading: false
        });
      });
  };

  componentDidMount() {
    this.getPrices();
    this.getTestimonials();
  }

  componentWillMount() {
    let lang = "HE";
    if (lang === "HE") {
      $("html").attr("dir", "rtl");
      $("html").attr("lang", lang);
      this.render();
    }
  }

  render() {
    let { prices, testimonials } = this.state;
    let lang = $("html")
      .attr("lang")
      .toUpperCase();
    let t = T[0];
    return (
      <div className="wrapper">
        <div className="container">
          <nav className="navbar navbar-default navbar-fixed-top">
            <div className="container">
              <div className="navbar-header page-scroll">
                <button
                  type="button"
                  className="navbar-toggle"
                  data-toggle="collapse"
                  data-target="#bs-example-navbar-collapse-1"
                  aria-expanded="false"
                >
                  <span className="sr-only">Toggle navigation</span>
                  <span className="icon-bar"></span>
                  <span className="icon-bar"></span>
                  <span className="icon-bar"></span>
                </button>
                <a
                  className="navbar-brand page-scroll"
                  href="https://dev.easy-card.co/#/"
                >
                  <img
                    src="/assets/images/easy-card.png"
                    width="105"
                    height="35"
                    alt="iLand"
                  />
                </a>
              </div>
              <div
                className="collapse navbar-collapse navbar-right"
                id="bs-example-navbar-collapse-1"
                style={{ display: "none" }}
              >
                <div className="tm-link-cont">
                  <div className="tm-arrow-wrapper">
                    <svg className="svg-icon-left" aria-hidden="true">
                      <use xlinkHref="/assets/images/svg-icons/sprite.svg#left-arrow-icon"></use>
                    </svg>
                  </div>
                  <Link to="/" className="tm-link">
                    Back
                  </Link>
                </div>
              </div>
            </div>
          </nav>
        </div>
        <div className="main form" id="main">
          <div className="container">
            <div className="col-md-12">
              <div className="tm-main"></div>
              <div className="hcp">
                <h2>מדיניות ביטול שירותים וזיכויים</h2>
                <h1>כללי</h1>
                <div className="cpp">
                  <ol>
                    <li>
                      שירותים המוענקים על-ידיא.ב יזמות עסקית וחברתית(להלן "{" "}
                      <span>החברה</span>"){" "}
                    </li>
                    <li>
                      שירותי החברה נמכרים לאנשי עסקים, מנהלים, עוסקים מורשים,
                      עוסקים פטורים בעלי חברות וכיו"ב, וכן לאנשים פרטיים למטרה
                      אישית לשם קידום מטרותיהם העסקיות ועסקיהם (להלן: "
                      <span>לקוח</span>")
                    </li>
                    <li>
                      ביטול רכישת שירותים וזיכוי כספי אפשרי בהתאם לדין ולמדיניות
                      החברה, אשר תפורט להלן.
                    </li>
                    <li>
                      מדיניות זו היא המדיניות היחידה המחייבת הן את החברה והן את
                      לקוחותיה, אך אין בה כדי לפגוע בזכותם של לקוחותיה לבטל עסקה
                      על פי החוק כאשר הוראות החוק מטיבות וגוברות על המתואר בכתב
                      מדיניות זה.
                    </li>
                    <li>
                      האמור להלן מסכם את כל אפשרויות הביטול, ההחזרים והזיכויים,
                      שומרת לעצמה את הזכות לאפשר, על פי שיקול דעתה הבלעדי, ללקוח
                      להמיר שירות אחד באחר, במידה ויראה הדבר נכון לחברה בנסיבות
                      העניין.
                    </li>
                    <li>
                      להלן יפורטו סוגי השירותים שמספקת החברה:
                      <ol>
                        <li>
                          מסלול חודשי: מנוי חודשי לא מוגבל בזמן, כאשר החודש
                          הראשון לאחר ההצטרפות לשירות הינו בחינם, וזאת בהתאם
                          לחבילות שמפורטות באתר, שנותנות לשינוי לפי שיקול דעת
                          החברה.
                        </li>
                        <li>
                          מסלול שנתי: מנוי שנתי במחיר מוזל, התשלום הינו בעת
                          רכישת המנוי.
                        </li>
                      </ol>
                    </li>
                    <li>
                      על לקוח אשר מימש את זכותו לבטל ולקבל החזר כספי כמפורט
                      בתקנון זה ו/או על פי הדין יחולו ההוראות הבאות:
                      <ol>
                        <li>
                          הלקוח יכול לבחור באחד משני המסלולים הבאים: מסלול שנתי
                          או מסלול חודשי, הכל כפי שפורט בסעיף 6.
                        </li>
                        <li>
                          בעת כריתת העסקה בין הצדדים, הלקוח ייהנה משימוש חינמי
                          לחודש ימים, בשירות שמספקת החברה
                        </li>
                        <li>
                          לקוח שמעוניין לבטל את רכישת השירות במסלול השנתי יחויב
                          באופן יחסי בגין אותה שנה, בתנאי שיודיע על כך לחברה עד
                          14 יום בטרם הפסקת השירות
                        </li>
                        <li>
                          לקוח שמעוניין לבטל את רכישת השירות במסלול החודשי,
                          יחויב בתשלום מלא בגין אותו החודש, בתנאי שהלקוח מודיע
                          על כך לחברה עד 14 יום טרם הפסקת השירות.
                        </li>
                        <li>
                          במסלול השנתי, הלקוח לא יכול לבטל רכישת שירות מהחברה
                          יותר מפעם אחת בשנתיים.
                        </li>
                        <li>
                          במסלול החודשי, הלקוח לא יכול לבטל רכישת שירות מהחברה
                          יותר מפעם אחת בשנה, הכל לפי שיקול דעת החברה
                        </li>
                      </ol>
                    </li>
                    <li>
                      ככל שהלקוח רכש אחד משירותי החברה באמצעות שובר הנחה וביטל
                      את השירות האמור, החברה לא מתחייבת לספק את אותו השירות
                      באותה העלות. יהא על הלקוח לרכוש את שירותי החברה בהתאם
                      למסלולים שפורטו לעיל
                    </li>
                    <li>
                      האמור במדיניות זו בלשון זכר נעשה לשם הנוחות בלבד ודין זכר
                      כדין נקבה ולהפך, ודין יחיד כדין רבים ולהפך, הכל לפי
                      העניין.
                    </li>
                    <li>
                      האמור לעיל מסכם את כל אפשרויות ביטול עסקאות, הפסקת שירות,
                      ההחזרים והזיכויים, אך החברה שומרת לעצמה את הזכות לאפשר, על
                      פי שיקול דעתה הבלעדי, ללקוח להמיר שירות אחד באחר, במידה
                      ויראה הדבר נכון לחברה בנסיבות העניין
                    </li>
                    <li>
                      אנו מאחלים לך להשתמש ולהפיק תועלת משמעותית מכל אחד
                      מהמוצרים והשירותים שלנו
                    </li>
                  </ol>
                </div>
              </div>
            </div>
          </div>
          <div className="container-contact100" id="contact-us">
            <div className="wrap-contact100">
              <form className="contact100-form validate-form">
                {" "}
                <span className="contact100-form-title">Contact Us</span>
                <div
                  className="wrap-input100 rs1-wrap-input100 validate-input"
                  data-validate="Type first name"
                >
                  <input
                    id="first-name"
                    className="input100"
                    type="text"
                    name="first-name"
                    placeholder="First name"
                    required
                  />{" "}
                  <span className="focus-input100"></span>
                </div>
                <div
                  className="wrap-input100 rs2-wrap-input100 validate-input"
                  data-validate="Type last name"
                >
                  <input
                    className="input100"
                    type="text"
                    name="last-name"
                    placeholder="Last name"
                    required
                  />{" "}
                  <span className="focus-input100"></span>
                </div>
                <div
                  className="wrap-input100 validate-input"
                  data-validate="Valid email is required: ex@abc.xyz"
                >
                  <input
                    id="email"
                    className="input100"
                    type="text"
                    name="email"
                    placeholder="Enter email"
                    required
                  />{" "}
                  <span className="focus-input100"></span>
                </div>
                <div className="wrap-input100">
                  <input
                    id="phone"
                    className="input100"
                    type="text"
                    name="phone"
                    placeholder="Phone Number"
                    required
                  />{" "}
                  <span className="focus-input100"></span>
                </div>
                <div
                  className="wrap-input100 validate-input"
                  data-validate="Message is required"
                >
                  <textarea
                    id="message"
                    className="input100"
                    name="message"
                    placeholder="Leave us a message"
                    required
                  ></textarea>{" "}
                  <span className="focus-input100"></span>
                </div>
                <div className="container-contact100-form-btn">
                  <button className="contact100-form-btn btn4">
                    SEND MESSAGE
                  </button>
                </div>
              </form>
              <div className="contact100-more flex-col-c-m">
                <div className="size1">
                  <div className="flex-col size2">
                    <p className="txt1 p-b-20">Contact us via</p>
                    <br />
                    <div className="contact-flex-containter">
                      <a
                        className="flex-contact-item"
                        href="mailto:support@easy-card.co"
                      >
                        <svg className="svg-icon-contact" aria-hidden="true">
                          <use xlinkHref="/assets/images/svg-icons/sprite.svg#envelope-icon"></use>
                        </svg>
                      </a>
                      <a
                        className="flex-contact-item"
                        href="https://www.facebook.com/EasyCard"
                      >
                        <svg className="svg-icon-contact" aria-hidden="true">
                          <use xlinkHref="/assets/images/svg-icons/sprite.svg#facebook-icon"></use>
                        </svg>
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <footer className="container footer-distributed">
            <div className="footer-left">
              <img src="/assets/images/footer-logo.png" alt="Easy Card" />
              <p className="footer-links">
                {" "}
                <a className="page-scroll" href="#main">
                  Home
                </a>
                ·{" "}
                <a className="page-scroll" href="#features">
                  Product Features
                </a>
                ·{" "}
                <a className="page-scroll" href="#price">
                  Price
                </a>
                ·{" "}
                <a className="page-scroll" href="#how-it-works">
                  How It Works
                </a>
                ·{" "}
                <a className="page-scroll" href="#img-slider-background">
                  Examples
                </a>
                ·{" "}
                <a className="page-scroll" href="#reviews">
                  Reviews
                </a>
                ·{" "}
                <a className="page-scroll" href="#contact-us">
                  Contact Us
                </a>
                ·{" "}
                <Link
                  className="page-scroll"
                  to="/terms/and/conditions"
                  target="_blank"
                >
                  Terms And Conditions
                </Link>
                ·{" "}
                <Link
                  className="page-scroll"
                  to="/privacy/policy"
                  target="_blank"
                >
                  Privacy Policy
                </Link>
                ·{" "}
                <Link
                  className="page-scroll"
                  to="/Cancellation/Policy"
                  target="_blank"
                >
                  Cancellation Policy
                </Link>
              </p>
              <p className="footer-company-name">
                © 2020 Easy Card | All rights reserved.
              </p>
            </div>
            <div className="footer-center">
              <div>
                {" "}
                <i className="fa fa-envelope"></i>
                <p>
                  <a href="mailto:support@easy-card.co">support@easy-card.co</a>
                </p>
              </div>
              <div>
                {" "}
                <i className="fab fa-facebook-f"></i>
                <p>
                  <a href="https://www.facebook.com/EasyCard">
                    www.facebook.com/EasyCard
                  </a>
                </p>
              </div>
            </div>
            <div className="footer-right">
              <p className="footer-company-about">
                {" "}
                <span>Business address</span>{" "}
                <a href="#">Yafa Yarkoni 14, Rosh Ha'ayin, Israel</a>{" "}
              </p>
              <br />
              <p className="footer-company-about">
                {" "}
                <span>Phone number</span>
                <a href="#">+972-54-9950199</a>{" "}
              </p>
              <br />
              <p className="footer-company-about">
                {" "}
                <span>Link</span>·{" "}
                <a href="https://portal.easy-card.co/">Sign In</a> &nbsp;&nbsp;
                · <a href="https://portal.easy-card.co/#/register">Sign Up</a>
              </p>
              <div className="footer-icons">
                <a
                  href="https://portal.easy-card.co/#/register"
                  className="btn btn5"
                >
                  Create your own Easy Card
                </a>
              </div>
            </div>
            <br />
          </footer>
        </div>
        <a id="back-top" className="back-to-top page-scroll" href="#main">
          {" "}
          <i className="ion-ios-arrow-thin-up"></i>
        </a>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    user: state.AuthReducer.user
  };
};
const mapDispatchToProps = () => {
  return {
    getPrices: () => userActionCreater.getPrices(),
    getTestimonials: () => userActionCreater.getTestimonials()
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(CancellationPolicy);
