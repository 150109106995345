import React, { Component } from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import $ from "jquery";
import T from "../Helpers/Translations";
import * as actions from "../Store/Actions/type";
import * as userActionCreater from "../Store/Actions/UserActions";

class CancellationPolicy extends Component {
  state = {
    user: {},
    prices: {}
  };

  getPrices = () => {
    let { getPrices, errorHandler } = this.props;
    getPrices()
      .then(res => {
        this.setState({
          prices: res.data
        });
      })
      .catch(errorHandler)
      .finally(() => {
        this.setState({
          isLoading: false
        });
      });
  };

  getTestimonials = () => {
    let { getTestimonials, errorHandler } = this.props;
    getTestimonials()
      .then(res => {
        this.setState({
          testimonials: res.data
        });
      })
      .catch(errorHandler)
      .finally(() => {
        this.setState({
          isLoading: false
        });
      });
  };

  componentDidMount() {
    this.getPrices();
    this.getTestimonials();
  }

  componentWillMount() {
    let lang = "HE";
    if (lang === "HE") {
      $("html").attr("dir", "rtl");
      $("html").attr("lang", lang);
      this.render();
    }
  }

  render() {
    let { prices, testimonials } = this.state;
    let lang = $("html")
      .attr("lang")
      .toUpperCase();
    let t = T[0];
    return (
      <div className="wrapper">
        <div className="container">
          <nav className="navbar navbar-default navbar-fixed-top">
            <div className="container">
              <div className="navbar-header page-scroll">
                <button
                  type="button"
                  className="navbar-toggle"
                  data-toggle="collapse"
                  data-target="#bs-example-navbar-collapse-1"
                  aria-expanded="false"
                >
                  <span className="sr-only">Toggle navigation</span>
                  <span className="icon-bar"></span>
                  <span className="icon-bar"></span>
                  <span className="icon-bar"></span>
                </button>
                <a
                  className="navbar-brand page-scroll"
                  href="https://dev.easy-card.co/#/"
                >
                  <img
                    src="/assets/images/easy-card.png"
                    width="105"
                    height="35"
                    alt="iLand"
                  />
                </a>
              </div>
              <div
                className="collapse navbar-collapse navbar-right"
                id="bs-example-navbar-collapse-1"
                style={{ display: "none" }}
              >
                <div className="tm-link-cont">
                  <div className="tm-arrow-wrapper">
                    <svg className="svg-icon-left" aria-hidden="true">
                      <use xlinkHref="/assets/images/svg-icons/sprite.svg#left-arrow-icon"></use>
                    </svg>
                  </div>
                  <Link to="/" className="tm-link">
                    Back
                  </Link>
                </div>
              </div>
            </div>
          </nav>
        </div>
        <div className="main form" id="main">
          <div className="container">
            <div className="col-md-12">
              <div className="tm-main"></div>
              <div className="hcp">
                <h2>سياسة إلغاء الخدمات واسترداد الأموال</h2>
                <h1>عام</h1>
                <div className="cpp">
                  <ol>
                    <li>
                      يتم تقديم الخدمات من طرف شركة A.B.لريادة الأعمالالتجارية
                      والاجتماعية (المشار إليها فيما يلي باسم "
                      <span>الشركة</span>").
                    </li>
                    <li>
                      يتم بيع خدمات الشركة لرجال الأعمال والمديرين والشركات
                      المسجلة والشركات المعفاة من الضرائب وأصحاب الشركات وغيرهم،
                      فضلا عن الأشخاص العاديين من أجل الاستخدام الشخصي وللنهوض
                      بالشركات والأهداف التجارية (مشار إليهم فيما بعد باسم "
                      <span>العملاء</span>").
                    </li>
                    <li>
                      يمكن إلغاء الخدمات واسترداد الأموال وفقا للقانون وحسب
                      سياسة الشركة الموضحة أدناه.
                    </li>
                    <li>
                      . تشكل وثيقة السياسة هذه السياسة الوحيدة التي تحكم الشركة
                      وعملائها، إلا أنها لا تقلل من حق العملاء في إلغاء
                      المعاملات وفقا للقانون عندما تكون أحكام القانون أعلى وأكثر
                      تفوقا على الأحكام الواردة في هذه السياسة.
                    </li>
                    <li>
                      فيما يلي ملخص لجميع خيارات الإلغاء واسترداد الأموال
                      والائتمان. وتحتفظ الشركة، وفقا لتقديرها الخاص، بالحق في
                      السماح للعميل باستبدال خدمة مقابل خدمة أخرى عندما ترى
                      الشركة أن القيام بذلك مبرر في الظروف الراهنة.
                    </li>
                    <li>
                      يتم توفير أنواع الخدمات التالية من طرف الشركة:
                      <ol>
                        <li>
                          الخطة الشهرية: الاشتراك الشهري غير محدود في الفترة،
                          ويكون الشهر الأول بعد الانضمام إلى الخدمة مجانيا وذلك
                          حسب الباقات المشروحة بشكل مفصل في الموقع الإلكتروني
                          والتي تخضع للتغيير حسب تقدير الشركة.
                        </li>
                        <li>
                          الخطة السنوية: اشتراك سنوي بسعر مخفض؛ يتم الدفع في نفس
                          وقت شراء باقة الاشتراك.
                        </li>
                      </ol>
                    </li>
                    <li>
                      تنطبق الأحكام التالية على العميل الذي يمارس حقه في الإلغاء
                      واسترداد الأموال كما هو موضح في هذه السياسة و/أو وفقا
                      للقانون:
                      <ol>
                        <li>
                          يمكن للعميل اختيار أحد الخطتين التاليتين: الخطة
                          السنوية أو الخطة الشهرية كما هو مفصل في الفقرة
                          السادسة.
                        </li>
                        <li>
                          عند تنفيذ العقد بين الأطراف، سوف يتمتع العميل
                          بالاستخدام المجاني للخدمة المقدمة من طرف الشركة لمدة
                          شهر واحد.
                        </li>
                        <li>
                          إذا أراد عميل مشترك في الخطة السنوية إلغاء شراءه
                          للخدمة، سيتم احتساب رسوم على العميل تناسب فترة
                          الاستفادة من الاشتراك من تلك السنة، بشرط أن يقوم
                          العميل بإخطار الشركة بتوقف الخدمة قبل 14 يوما كحد
                          أقصى.
                        </li>
                        <li>
                          إذا أراد عميل مشترك في الخطة الشهرية إلغاء شراء
                          الخدمة، سيتم احتساب رسوم الشهر كاملة، على أن يقوم
                          العميل بإخطار الشركة بتوقف الخدمة قبل 14 يوما كحد
                          أقصى.
                        </li>
                        <li>
                          لا يمكن للعميل المشترك في الخطة السنوية إلغاء الخدمة
                          المقدمة من طرف الشركة أكثر من مرة واحدة كل سنتين.
                        </li>
                        <li>
                          لا يمكن للعميل المشترك في الخطة الشهرية إلغاء الخدمة
                          المقدمة من طرف الشركة أكثر من مرة واحدة في السنة، وكل
                          ذلك وفقا لتقدير الشركة.
                        </li>
                      </ol>
                    </li>
                    <li>
                      في حالة شراء إحدى خدمات الشركة بقسيمة خصم وقيام العميل
                      بإلغاء الخدمة المذكورة أعلاه، فلن تكون الشركة ملزمة بتقديم
                      نفس الخدمة بنفس التكلفة. وسوف يتعين على العميل شراء خدمات
                      الشركة وفقا للخطط المذكورة أعلاه.
                    </li>
                    <li>
                      . الإشارة إلى النوع الذكوري في هذه السياسة هي لدواعي
                      المواءمة فقط، فهده السياسة تنطبق على الذكور والإناث على حد
                      سواء. ونفس الأمر ينطبق على استخدام المفرد عوض الجمع؛ فكل
                      استخدام كان لدواعي المواءمة فقط.
                    </li>
                    <li>
                      يلخص ما سبق كل خيارات إلغاء المعاملات وإنهاء الخدمة
                      واسترداد الأموال، إلا أن الشركة تحتفظ بالحق، وفقا لتقديرها
                      الخاص، في السماح للعميل باستبدال خدمة واحدة مقابل خدمة
                      أخرى إذا رأت الشركة أن القيام بذلك مبرر في الظروف الراهنة.
                    </li>
                    <li>
                      نتمنى لكم استخدام وتحقيق الاستفادة القصوى من منتجاتنا
                      وخدماتنا.
                    </li>
                  </ol>
                </div>
              </div>
            </div>
          </div>
          <div className="container-contact100" id="contact-us">
            <div className="wrap-contact100">
              <form className="contact100-form validate-form">
                {" "}
                <span className="contact100-form-title">Contact Us</span>
                <div
                  className="wrap-input100 rs1-wrap-input100 validate-input"
                  data-validate="Type first name"
                >
                  <input
                    id="first-name"
                    className="input100"
                    type="text"
                    name="first-name"
                    placeholder="First name"
                    required
                  />{" "}
                  <span className="focus-input100"></span>
                </div>
                <div
                  className="wrap-input100 rs2-wrap-input100 validate-input"
                  data-validate="Type last name"
                >
                  <input
                    className="input100"
                    type="text"
                    name="last-name"
                    placeholder="Last name"
                    required
                  />{" "}
                  <span className="focus-input100"></span>
                </div>
                <div
                  className="wrap-input100 validate-input"
                  data-validate="Valid email is required: ex@abc.xyz"
                >
                  <input
                    id="email"
                    className="input100"
                    type="text"
                    name="email"
                    placeholder="Enter email"
                    required
                  />{" "}
                  <span className="focus-input100"></span>
                </div>
                <div className="wrap-input100">
                  <input
                    id="phone"
                    className="input100"
                    type="text"
                    name="phone"
                    placeholder="Phone Number"
                    required
                  />{" "}
                  <span className="focus-input100"></span>
                </div>
                <div
                  className="wrap-input100 validate-input"
                  data-validate="Message is required"
                >
                  <textarea
                    id="message"
                    className="input100"
                    name="message"
                    placeholder="Leave us a message"
                    required
                  ></textarea>{" "}
                  <span className="focus-input100"></span>
                </div>
                <div className="container-contact100-form-btn">
                  <button className="contact100-form-btn btn4">
                    SEND MESSAGE
                  </button>
                </div>
              </form>
              <div className="contact100-more flex-col-c-m">
                <div className="size1">
                  <div className="flex-col size2">
                    <p className="txt1 p-b-20">Contact us via</p>
                    <br />
                    <div className="contact-flex-containter">
                      <a
                        className="flex-contact-item"
                        href="mailto:support@easy-card.co"
                      >
                        <svg className="svg-icon-contact" aria-hidden="true">
                          <use xlinkHref="/assets/images/svg-icons/sprite.svg#envelope-icon"></use>
                        </svg>
                      </a>
                      <a
                        className="flex-contact-item"
                        href="https://www.facebook.com/EasyCard"
                      >
                        <svg className="svg-icon-contact" aria-hidden="true">
                          <use xlinkHref="/assets/images/svg-icons/sprite.svg#facebook-icon"></use>
                        </svg>
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <footer className="container footer-distributed">
            <div className="footer-left">
              <img src="/assets/images/footer-logo.png" alt="Easy Card" />
              <p className="footer-links">
                {" "}
                <a className="page-scroll" href="#main">
                  Home
                </a>
                ·{" "}
                <a className="page-scroll" href="#features">
                  Product Features
                </a>
                ·{" "}
                <a className="page-scroll" href="#price">
                  Price
                </a>
                ·{" "}
                <a className="page-scroll" href="#how-it-works">
                  How It Works
                </a>
                ·{" "}
                <a className="page-scroll" href="#img-slider-background">
                  Examples
                </a>
                ·{" "}
                <a className="page-scroll" href="#reviews">
                  Reviews
                </a>
                ·{" "}
                <a className="page-scroll" href="#contact-us">
                  Contact Us
                </a>
                ·{" "}
                <Link
                  className="page-scroll"
                  to="/terms/and/conditions"
                  target="_blank"
                >
                  Terms And Conditions
                </Link>
                ·{" "}
                <Link
                  className="page-scroll"
                  to="/privacy/policy"
                  target="_blank"
                >
                  Privacy Policy
                </Link>
                ·{" "}
                <Link
                  className="page-scroll"
                  to="/Cancellation/Policy"
                  target="_blank"
                >
                  Cancellation Policy
                </Link>
              </p>
              <p className="footer-company-name">
                © 2020 Easy Card | All rights reserved.
              </p>
            </div>
            <div className="footer-center">
              <div>
                {" "}
                <i className="fa fa-envelope"></i>
                <p>
                  <a href="mailto:support@easy-card.co">support@easy-card.co</a>
                </p>
              </div>
              <div>
                {" "}
                <i className="fab fa-facebook-f"></i>
                <p>
                  <a href="https://www.facebook.com/EasyCard">
                    www.facebook.com/EasyCard
                  </a>
                </p>
              </div>
            </div>
            <div className="footer-right">
              <p className="footer-company-about">
                {" "}
                <span>Business address</span>{" "}
                <a href="#">Yafa Yarkoni 14, Rosh Ha'ayin, Israel</a>{" "}
              </p>
              <br />
              <p className="footer-company-about">
                {" "}
                <span>Phone number</span>
                <a href="#">+972-54-9950199</a>{" "}
              </p>
              <br />
              <p className="footer-company-about">
                {" "}
                <span>Link</span>·{" "}
                <a href="https://portal.easy-card.co/">Sign In</a> &nbsp;&nbsp;
                · <a href="https://portal.easy-card.co/#/register">Sign Up</a>
              </p>
              <div className="footer-icons">
                <a
                  href="https://portal.easy-card.co/#/register"
                  className="btn btn5"
                >
                  Create your own Easy Card
                </a>
              </div>
            </div>
            <br />
          </footer>
        </div>
        <a id="back-top" className="back-to-top page-scroll" href="#main">
          {" "}
          <i className="ion-ios-arrow-thin-up"></i>
        </a>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    user: state.AuthReducer.user
  };
};
const mapDispatchToProps = () => {
  return {
    getPrices: () => userActionCreater.getPrices(),
    getTestimonials: () => userActionCreater.getTestimonials()
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(CancellationPolicy);
