import React, { Component } from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import $ from "jquery";
import T from "../Helpers/Translations";
import * as actions from "../Store/Actions/type";
import * as userActionCreater from "../Store/Actions/UserActions";

class PrivacyPolicy extends Component {
  state = {
    user: {},
    prices: {}
  };

  getPrices = () => {
    let { getPrices, errorHandler } = this.props;
    getPrices()
      .then(res => {
        this.setState({
          prices: res.data
        });
      })
      .catch(errorHandler)
      .finally(() => {
        this.setState({
          isLoading: false
        });
      });
  };

  getTestimonials = () => {
    let { getTestimonials, errorHandler } = this.props;
    getTestimonials()
      .then(res => {
        this.setState({
          testimonials: res.data
        });
      })
      .catch(errorHandler)
      .finally(() => {
        this.setState({
          isLoading: false
        });
      });
  };

  componentDidMount() {
    this.getPrices();
    this.getTestimonials();
  }

  componentWillMount() {
    if (
      this.props.user !== null &&
      this.props.user !== "null" &&
      this.props.user !== undefined
    ) {
      this.setState({
        user: this.props.user
      });
      let lang = this.props.user.language;
      if (lang === "HE" || lang === "AR") {
        $("html").attr("dir", "rtl");
        $("html").attr("lang", lang);
        this.render();
      } else {
        $("html").attr("dir", "ltr");
        $("html").attr("lang", lang);
        this.render();
      }
    }
  }

  render() {
    let { prices, testimonials } = this.state;
    let lang = $("html")
      .attr("lang")
      .toUpperCase();
    let t = T[0];
    return (
      <div className="wrapper">
        <div className="container">
          <nav className="navbar navbar-default navbar-fixed-top">
            <div className="container">
              <div className="navbar-header page-scroll">
                <button
                  type="button"
                  className="navbar-toggle"
                  data-toggle="collapse"
                  data-target="#bs-example-navbar-collapse-1"
                  aria-expanded="false"
                >
                  <span className="sr-only">Toggle navigation</span>
                  <span className="icon-bar"></span>
                  <span className="icon-bar"></span>
                  <span className="icon-bar"></span>
                </button>
                <a
                  className="navbar-brand page-scroll"
                  href="https://dev.easy-card.co/#/"
                >
                  <img
                    src="/assets/images/easy-card.png"
                    width="105"
                    height="35"
                    alt="iLand"
                  />
                </a>
              </div>
              <div
                className="collapse navbar-collapse navbar-right"
                id="bs-example-navbar-collapse-1"
                style={{ display: "none" }}
              >
                <div className="tm-link-cont">
                  <div className="tm-arrow-wrapper">
                    <svg className="svg-icon-left" aria-hidden="true">
                      <use xlinkHref="/assets/images/svg-icons/sprite.svg#left-arrow-icon"></use>
                    </svg>
                  </div>
                  <Link to="/" className="tm-link">
                    Back
                  </Link>
                </div>
              </div>
            </div>
          </nav>
        </div>
        <div className="main form" id="main">
          <div className="container">
            <div className="col-md-12">
              <div className="tm-main">
                <h1>Privacy Policy</h1>
                <br />
                <p>
                  This privacy policy ("Policy") describes how Website Operator
                  ("Website Operator", "we", "us" or "our") collects, protects
                  and uses the personally identifiable information ("Personal
                  Information") you ("User", "you" or "your") may provide on the
                  easy-card.co website and any of its products or services
                  (collectively, "Website" or "Services"). It also describes the
                  choices available to you regarding our use of your Personal
                  Information and how you can access and update this
                  information. This Policy does not apply to the practices of
                  companies that we do not own or control, or to individuals
                  that we do not employ or manage.
                </p>

                <div className="tm-card">
                  <h2>Automatic collection of information</h2>
                  <p>
                    When you visit the Website our servers automatically record
                    information that your browser sends. This data may include
                    information such as your device's IP address, browser type
                    and version, operating system type and version, language
                    preferences or the webpage you were visiting before you came
                    to our Website, pages of our Website that you visit, the
                    time spent on those pages, information you search for on our
                    Website, access times and dates, and other statistics.
                  </p>
                </div>

                <div className="tm-card">
                  <h2>Collection of personal information</h2>
                  <p>
                    You can visit the Website without telling us who you are or
                    revealing any information by which someone could identify
                    you as a specific, identifiable individual. If, however, you
                    wish to use some of the Website's features, you will be
                    asked to provide certain Personal Information (for example,
                    your name and e-mail address). We receive and store any
                    information you knowingly provide to us when you create an
                    account, publish content, make a purchase, or fill any
                    online forms on the Website. When required, this information
                    may include your email address, name, phone number, or other
                    Personal Information. You can choose not to provide us with
                    your Personal Information, but then you may not be able to
                    take advantage of some of the Website's features. Users who
                    are uncertain about what information is mandatory are
                    welcome to contact us.
                  </p>
                </div>

                <div className="tm-card">
                  <h2>Managing personal information</h2>
                  <p>
                    You are able to access, add to, update and delete certain
                    Personal Information about you. The information you can
                    view, update, and delete may change as the Website or
                    Services change. When you update information, however, we
                    may maintain a copy of the unrevised information in our
                    records. Some information may remain in our private records
                    after your deletion of such information from your account.
                    We will retain and use your Personal Information for the
                    period necessary to comply with our legal obligations,
                    resolve disputes, and enforce our agreements unless a longer
                    retention period is required or permitted by law. We may use
                    any aggregated data derived from or incorporating your
                    Personal Information after you update or delete it, but not
                    in a manner that would identify you personally. Once the
                    retention period expires, Personal Information shall be
                    deleted. Therefore, the right to access, the right to
                    erasure, the right to rectification and the right to data
                    portability cannot be enforced after the expiration of the
                    retention period.
                  </p>
                </div>

                <div className="tm-card">
                  <h2>Use and processing of collected information</h2>
                  <p>
                    Any of the information we collect from you may be used to
                    personalize your experience; improve our Website; improve
                    customer service and respond to queries and emails of our
                    customers; process transactions; send notification emails
                    such as password reminders, updates, etc; run and operate
                    our Website and Services. Information collected
                    automatically is used only to identify potential cases of
                    abuse and establish statistical information regarding
                    Website usage. This statistical information is not otherwise
                    aggregated in such a way that would identify any particular
                    user of the system.
                  </p>
                  <p>
                    We may process Personal Information related to you if one of
                    the following applies: (i) You have given your consent for
                    one or more specific purposes. Note that under some
                    legislations we may be allowed to process information until
                    you object to such processing (by opting out), without
                    having to rely on consent or any other of the following
                    legal bases below. This, however, does not apply, whenever
                    the processing of Personal Information is subject to
                    European data protection law; (ii) Provision of information
                    is necessary for the performance of an agreement with you
                    and/or for any pre-contractual obligations thereof; (iii)
                    Processing is necessary for compliance with a legal
                    obligation to which you are subject; (iv) Processing is
                    related to a task that is carried out in the public interest
                    or in the exercise of official authority vested in us; (v)
                    Processing is necessary for the purposes of the legitimate
                    interests pursued by us or by a third party. In any case, we
                    will be happy to clarify the specific legal basis that
                    applies to the processing, and in particular whether the
                    provision of Personal Information is a statutory or
                    contractual requirement, or a requirement necessary to enter
                    into a contract.
                  </p>
                </div>

                <div className="tm-card">
                  <h2>Information transfer and storage</h2>
                  <p>
                    Depending on your location, data transfers may involve
                    transferring and storing your information in a country other
                    than your own. You are entitled to learn about the legal
                    basis of information transfers to a country outside the
                    European Union or to any international organization governed
                    by public international law or set up by two or more
                    countries, such as the UN, and about the security measures
                    taken by us to safeguard your information. If any such
                    transfer takes place, you can find out more by checking the
                    relevant sections of this document or inquire with us using
                    the information provided in the contact section
                  </p>
                </div>

                <div className="tm-card">
                  <h2>The rights of users</h2>
                  <p>
                    You may exercise certain rights regarding your information
                    processed by us. In particular, you have the right to do the
                    following: (i) you have the right to withdraw consent where
                    you have previously given your consent to the processing of
                    your information; (ii) you have the right to object to the
                    processing of your information if the processing is carried
                    out on a legal basis other than consent; (iii) you have the
                    right to learn if information is being processed by us,
                    obtain disclosure regarding certain aspects of the
                    processing and obtain a copy of the information undergoing
                    processing; (iv) you have the right to verify the accuracy
                    of your information and ask for it to be updated or
                    corrected; (v) you have the right, under certain
                    circumstances, to restrict the processing of your
                    information, in which case, we will not process your
                    information for any purpose other than storing it; (vi) you
                    have the right, under certain circumstances, to obtain the
                    erasure of your Personal Information from us; (vii) you have
                    the right to receive your information in a structured,
                    commonly used and machine readable format and, if
                    technically feasible, to have it transmitted to another
                    controller without any hindrance. This provision is
                    applicable provided that your information is processed by
                    automated means and that the processing is based on your
                    consent, on a contract which you are part of or on
                    pre-contractual obligations thereof.
                  </p>
                </div>

                <div className="tm-card">
                  <h2>The right to object to processing</h2>
                  <p>
                    Where Personal Information is processed for the public
                    interest, in the exercise of an official authority vested in
                    us or for the purposes of the legitimate interests pursued
                    by us, you may object to such processing by providing a
                    ground related to your particular situation to justify the
                    objection. You must know that, however, should your Personal
                    Information be processed for direct marketing purposes, you
                    can object to that processing at any time without providing
                    any justification. To learn, whether we are processing
                    Personal Information for direct marketing purposes, you may
                    refer to the relevant sections of this document.
                  </p>
                </div>

                <div className="tm-card">
                  <h2>How to exercise these rights</h2>
                  <p>
                    Any requests to exercise User rights can be directed to the
                    Owner through the contact details provided in this document.
                    These requests can be exercised free of charge and will be
                    addressed by the Owner as early as possible.
                  </p>
                </div>

                <div className="tm-card">
                  <h2>Billing and payments</h2>
                  <p>
                    We use third-party payment processors to assist us in
                    processing your payment information securely. Such
                    third-party processors' use of your Personal Information is
                    governed by their respective privacy policies which may or
                    may not contain privacy protections as protective as this
                    Privacy Policy. We suggest that you review their respective
                    privacy policies.
                  </p>
                </div>

                <div className="tm-card">
                  <h2>Privacy of children</h2>
                  <p>
                    We do not knowingly collect any Personal Information from
                    children under the age of 13. If you are under the age of
                    13, please do not submit any Personal Information through
                    our Website or Service. We encourage parents and legal
                    guardians to monitor their children's Internet usage and to
                    help enforce this Policy by instructing their children never
                    to provide Personal Information through our Website or
                    Service without their permission. If you have reason to
                    believe that a child under the age of 13 has provided
                    Personal Information to us through our Website or Service,
                    please contact us. You must also be at least 16 years of age
                    to consent to the processing of your Personal Information in
                    your country (in some countries we may allow your parent or
                    guardian to do so on your behalf).
                  </p>
                </div>

                <div className="tm-card">
                  <h2>Cookies</h2>
                  <p>
                    The Website uses "cookies" to help personalize your online
                    experience. A cookie is a text file that is placed on your
                    hard disk by a web page server. Cookies cannot be used to
                    run programs or deliver viruses to your computer. Cookies
                    are uniquely assigned to you, and can only be read by a web
                    server in the domain that issued the cookie to you. We may
                    use cookies to collect, store, and track information for
                    statistical purposes to operate our Website and Services.
                    You have the ability to accept or decline cookies. Most web
                    browsers automatically accept cookies, but you can usually
                    modify your browser setting to decline cookies if you
                    prefer. To learn more about cookies and how to manage them,
                    visit internetcookies.org
                  </p>
                  <p>
                    In addition to using cookies and related technologies as
                    described above, we also may permit certain third-party
                    companies to help us tailor advertising that we think may be
                    of interest to users and to collect and use other data about
                    user activities on the Website. These companies may deliver
                    ads that might also place cookies and otherwise track user
                    behavior.
                  </p>
                </div>

                <div className="tm-card">
                  <h2>Do Not Track signals</h2>
                  <p>
                    Some browsers incorporate a Do Not Track feature that
                    signals to websites you visit that you do not want to have
                    your online activity tracked. Tracking is not the same as
                    using or collecting information in connection with a
                    website. For these purposes, tracking refers to collecting
                    personally identifiable information from consumers who use
                    or visit a website or online service as they move across
                    different websites over time. How browsers communicate the
                    Do Not Track signal is not yet uniform. As a result, this
                    Website is not yet set up to interpret or respond to Do Not
                    Track signals communicated by your browser. Even so, as
                    described in more detail throughout this Policy, we limit
                    our use and collection of your personal information.
                  </p>
                </div>

                <div className="tm-card">
                  <h2>Advertisement</h2>
                  <p>
                    We may display online advertisements and we may share
                    aggregated and non-identifying information about our
                    customers that we collect through the registration process
                    or through online surveys and promotions with certain
                    advertisers. We do not share personally identifiable
                    information about individual customers with advertisers. In
                    some instances, we may use this aggregated and
                    non-identifying information to deliver tailored
                    advertisements to the intended audience.
                  </p>
                </div>

                <div className="tm-card">
                  <h2>Links to other websites</h2>
                  <p>
                    Our Website contains links to other websites that are not
                    owned or controlled by us. Please be aware that we are not
                    responsible for the privacy practices of such other websites
                    or third-parties. We encourage you to be aware when you
                    leave our Website and to read the privacy statements of each
                    and every website that may collect Personal Information.
                  </p>
                </div>

                <div className="tm-card">
                  <h2>Information security</h2>
                  <p>
                    We secure information you provide on computer servers in a
                    controlled, secure environment, protected from unauthorized
                    access, use, or disclosure. We maintain reasonable
                    administrative, technical, and physical safeguards in an
                    effort to protect against unauthorized access, use,
                    modification, and disclosure of Personal Information in its
                    control and custody. However, no data transmission over the
                    Internet or wireless network can be guaranteed. Therefore,
                    while we strive to protect your Personal Information, you
                    acknowledge that (i) there are security and privacy
                    limitations of the Internet which are beyond our control;
                    (ii) the security, integrity, and privacy of any and all
                    information and data exchanged between you and our Website
                    cannot be guaranteed; and (iii) any such information and
                    data may be viewed or tampered with in transit by a
                    third-party, despite best efforts.
                  </p>
                </div>

                <div className="tm-card">
                  <h2>Data breach</h2>
                  <p>
                    In the event we become aware that the security of the
                    Website has been compromised or users Personal Information
                    has been disclosed to unrelated third parties as a result of
                    external activity, including, but not limited to, security
                    attacks or fraud, we reserve the right to take reasonably
                    appropriate measures, including, but not limited to,
                    investigation and reporting, as well as notification to and
                    cooperation with law enforcement authorities. In the event
                    of a data breach, we will make reasonable efforts to notify
                    affected individuals if we believe that there is a
                    reasonable risk of harm to the user as a result of the
                    breach or if notice is otherwise required by law. When we
                    do, we will send you an email, get in touch with you over
                    the phone.
                  </p>
                </div>

                <div className="tm-card">
                  <h2>Legal disclosure</h2>
                  <p>
                    We will disclose any information we collect, use or receive
                    if required or permitted by law, such as to comply with a
                    subpoena, or similar legal process, and when we believe in
                    good faith that disclosure is necessary to protect our
                    rights, protect your safety or the safety of others,
                    investigate fraud, or respond to a government request. In
                    the event we go through a business transition, such as a
                    merger or acquisition by another company, or sale of all or
                    a portion of its assets, your user account, and Personal
                    Information will likely be among the assets transferred.
                  </p>
                </div>

                <div className="tm-card">
                  <h2>Changes and amendments</h2>
                  <p>
                    We may update this Privacy Policy from time to time in our
                    discretion and will notify you of any material changes to
                    the way in which we treat Personal Information. When changes
                    are made, we will revise the updated date at the bottom of
                    this page. We may also provide notice to you in other ways
                    in our discretion, such as through contact information you
                    have provided. Any updated version of this Privacy Policy
                    will be effective immediately upon the posting of the
                    revised Privacy Policy unless otherwise specified. Your
                    continued use of the Website or Services after the effective
                    date of the revised Privacy Policy (or such other act
                    specified at that time) will constitute your consent to
                    those changes. However, we will not, without your consent,
                    use your Personal Data in a manner materially different than
                    what was stated at the time your Personal Data was
                    collected.
                  </p>
                </div>

                <div className="tm-card">
                  <h2>Acceptance of this policy</h2>
                  <p>
                    You acknowledge that you have read this Policy and agree to
                    all its terms and conditions. By using the Website or its
                    Services you agree to be bound by this Policy. If you do not
                    agree to abide by the terms of this Policy, you are not
                    authorized to use or access the Website and its Services.
                  </p>
                </div>

                <div className="tm-card">
                  <h2>Contacting us</h2>
                  <p>
                    If you would like to contact us to understand more about
                    this Privacy Policy or wish to contact us concerning any
                    matter relating to it, you may do so via the contact form or
                    send an email to
                    <i className="tm-italic">
                      <a href="mailto:support@easy-card.co">
                        {" "}
                        support@easy-card.co
                      </a>{" "}
                    </i>
                    <br />
                    <span>
                      This document was last updated on{" "}
                      <b className="tm-bold">October 29, 2019</b>
                    </span>
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="container-contact100" id="contact-us">
            <div className="wrap-contact100">
              <form className="contact100-form validate-form">
                {" "}
                <span className="contact100-form-title">Contact Us</span>
                <div
                  className="wrap-input100 rs1-wrap-input100 validate-input"
                  data-validate="Type first name"
                >
                  <input
                    id="first-name"
                    className="input100"
                    type="text"
                    name="first-name"
                    placeholder="First name"
                    required
                  />{" "}
                  <span className="focus-input100"></span>
                </div>
                <div
                  className="wrap-input100 rs2-wrap-input100 validate-input"
                  data-validate="Type last name"
                >
                  <input
                    className="input100"
                    type="text"
                    name="last-name"
                    placeholder="Last name"
                    required
                  />{" "}
                  <span className="focus-input100"></span>
                </div>
                <div
                  className="wrap-input100 validate-input"
                  data-validate="Valid email is required: ex@abc.xyz"
                >
                  <input
                    id="email"
                    className="input100"
                    type="text"
                    name="email"
                    placeholder="Enter email"
                    required
                  />{" "}
                  <span className="focus-input100"></span>
                </div>
                <div className="wrap-input100">
                  <input
                    id="phone"
                    className="input100"
                    type="text"
                    name="phone"
                    placeholder="Phone Number"
                    required
                  />{" "}
                  <span className="focus-input100"></span>
                </div>
                <div
                  className="wrap-input100 validate-input"
                  data-validate="Message is required"
                >
                  <textarea
                    id="message"
                    className="input100"
                    name="message"
                    placeholder="Leave us a message"
                    required
                  ></textarea>{" "}
                  <span className="focus-input100"></span>
                </div>
                <div className="container-contact100-form-btn">
                  <button className="contact100-form-btn btn4">
                    SEND MESSAGE
                  </button>
                </div>
              </form>
              <div className="contact100-more flex-col-c-m">
                <div className="size1">
                  <div className="flex-col size2">
                    <p className="txt1 p-b-20">Contact us via</p>
                    <br />
                    <div className="contact-flex-containter">
                      <a
                        className="flex-contact-item"
                        href="mailto:support@easy-card.co"
                      >
                        <svg className="svg-icon-contact" aria-hidden="true">
                          <use xlinkHref="/assets/images/svg-icons/sprite.svg#envelope-icon"></use>
                        </svg>
                      </a>
                      <a
                        className="flex-contact-item"
                        href="https://www.facebook.com/EasyCard"
                      >
                        <svg className="svg-icon-contact" aria-hidden="true">
                          <use xlinkHref="/assets/images/svg-icons/sprite.svg#facebook-icon"></use>
                        </svg>
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <footer className="container footer-distributed">
            <div className="footer-left">
              <img src="/assets/images/footer-logo.png" alt="Easy Card" />
              <p className="footer-links">
                {" "}
                <a className="page-scroll" href="#main">
                  Home
                </a>
                ·{" "}
                <a className="page-scroll" href="#features">
                  Product Features
                </a>
                ·{" "}
                <a className="page-scroll" href="#price">
                  Price
                </a>
                ·{" "}
                <a className="page-scroll" href="#how-it-works">
                  How It Works
                </a>
                ·{" "}
                <a className="page-scroll" href="#img-slider-background">
                  Examples
                </a>
                ·{" "}
                <a className="page-scroll" href="#reviews">
                  Reviews
                </a>
                ·{" "}
                <a className="page-scroll" href="#contact-us">
                  Contact Us
                </a>
                ·{" "}
                <Link
                  className="page-scroll"
                  to="/terms/and/conditions"
                  target="_blank"
                >
                  Terms And Conditions
                </Link>
                ·{" "}
                <Link
                  className="page-scroll"
                  to="/privacy/policy"
                  target="_blank"
                >
                  Privacy Policy
                </Link>
                ·{" "}
                <Link
                  className="page-scroll"
                  to="/Cancellation/Policy"
                  target="_blank"
                >
                  Cancellation Policy
                </Link>
              </p>
              <p className="footer-company-name">
                © 2020 Easy Card | All rights reserved.
              </p>
            </div>
            <div className="footer-center">
              <div>
                {" "}
                <i className="fa fa-envelope"></i>
                <p>
                  <a href="mailto:support@easy-card.co">support@easy-card.co</a>
                </p>
              </div>
              <div>
                {" "}
                <i className="fab fa-facebook-f"></i>
                <p>
                  <a href="https://www.facebook.com/EasyCard">
                    www.facebook.com/EasyCard
                  </a>
                </p>
              </div>
            </div>
            <div className="footer-right">
              <p className="footer-company-about">
                {" "}
                <span>Business address</span>{" "}
                <a href="#">Yafa Yarkoni 14, Rosh Ha'ayin, Israel</a>{" "}
              </p>
              <br />
              <p className="footer-company-about">
                {" "}
                <span>Phone number</span>
                <a href="#">+972-54-9950199</a>{" "}
              </p>
              <br />
              <p className="footer-company-about">
                {" "}
                <span>Link</span>·{" "}
                <a href="https://portal.easy-card.co/">Sign In</a> &nbsp;&nbsp;
                · <a href="https://portal.easy-card.co/#/register">Sign Up</a>
              </p>
              <div className="footer-icons">
                <a
                  href="https://portal.easy-card.co/#/register"
                  className="btn btn5"
                >
                  Create your own Easy Card
                </a>
              </div>
            </div>
            <br />
          </footer>
        </div>
        <a id="back-top" className="back-to-top page-scroll" href="#main">
          {" "}
          <i className="ion-ios-arrow-thin-up"></i>
        </a>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    user: state.AuthReducer.user
  };
};
const mapDispatchToProps = () => {
  return {
    getPrices: () => userActionCreater.getPrices(),
    getTestimonials: () => userActionCreater.getTestimonials()
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(PrivacyPolicy);
