class APIModel {
  //for server
  static URL = "https://backend.easy-card.co/";
  static HOST = "https://backend.easy-card.co/api/";
  static WEB_APP_URL = "https://easy-card.co/";

  // for local
  // static URL = "http://localhost:8000/api/";
  // static HOST = "http://localhost:8000/api/";
  // static URL = "http://backend.myeasycard.co";
  // static HOST = "http://backend.myeasycard.co/api/";
  // static WEB_APP_URL = "https://dev.easy-card.co/";
}

export default APIModel;
